// :root {
//   --background: #f8f8f8;
//   --primary: #fc624d;
//   --primary-transparent: rgba(252, 97, 77, 0.4);

//   --text-dark: #202020;
//   --text-medium: #3f3f3f;
// }

:root {
  // justification: http://whocanuse.com/?b=6600cc&c=fff&f=20&s=b
  --background: #6600cc;
  --background-transparent: rgba(102, 0, 204, 0.8);
  --background-transparent-light: rgba(102, 0, 204, 0.5);
  --foreground: #ffffff;
  --text: #3b3b3b;
  --text-light: #e6ccff;

  @media (prefers-color-scheme: light) {
    // justification: http://whocanuse.com/?b=ffffff&c=6600cc&f=20&s=b
    --background: #ffffff;
    --background-transparent: rgba(255, 255, 255, 0.8);
    --background-transparent-light: rgba(255, 255, 255, 0.5);
    --foreground: #6600cc;
    --text: #e6ccff;
    --text-light: #3b3b3b;
  }
}

:root {
  --kd: #ffeb3b;
  --k: #ffeb3b;
  --kr: #ffeb3b;

  --nx: #32ff00;
  --na: #32ff00;

  --o: #ff69b4;
  --nt: #ff69b4;
  --nc: #ff69b4;

  --mi: #0fc7de;
  --nb: #0fc7de;

  --dl: #fdff95;
  --s1: #fdff95;
  --s2: #fdff95;
  --si: #fdff95;

  --c1: #cfa2fc;

  @media (prefers-color-scheme: light) {
    --kd: #607d8b;
    --k: #607d8b;
    --kr: #607d8b;

    --nx: #03a9f4;
    --na: #03a9f4;

    --o: #ff69b4;
    --nt: #ff69b4;
    --nc: #ff69b4;

    --mi: #0fc7de;
    --nb: #0fc7de;

    --dl: #ff9800;
    --s1: #ff9800;
    --s2: #ff9800;
    --si: #ff9800;

    --c1: #a54aff;
  }
}
