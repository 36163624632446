.highlight {
  width: 100%;
  background-color: var(--background);
  color: var(--foreground);
  margin: 20px 0;
  border-radius: 15px;
  font-family: monospace !important;
  overflow-x: auto;
  padding: 0 15px;

  pre,
  pre > code,
  pre > code span {
    font-family: monospace;
    font-size: 14px;
  }

  .highlight.tsx,
  .highlight.typescript {
    margin: 0;
    padding: 20px 0;

    .kd {
      color: var(--kd);
    }

    .k {
      color: var(--k);
    }

    .nx {
      color: var(--nx);
    }

    .na {
      color: var(--na);
    }

    .nt {
      color: var(--nt);
    }

    .o {
      color: var(--o);
    }

    .p {
      color: currentColor;
    }

    .mi {
      color: var(--mi);
    }

    .s2 {
      color: var(--s2);
    }

    .si {
      color: var(--si);
    }

    .s1 {
      color: var(--s1);
    }

    .dl {
      color: var(--dl);
    }

    .kr {
      color: var(--kr);
    }

    .nc {
      color: var(--nc);
    }

    .c1 {
      color: var(--c1);
    }

    .nb {
      color: var(--nb);
    }
  }
}
