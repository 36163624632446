.my-work {
  h4 {
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 30px;
    color: var(--text);
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 70px;
      height: 2px;
      background-color: var(--background);
      bottom: -5px;
    }
  }

  .actions {
    text-align: center;

    a {
      display: inline-block;
      line-height: 45px;
      background-color: var(--background);
      padding: 0 20px;
      border-radius: 15px;
      font-weight: 300 !important;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: var(--foreground) !important;
    }
  }
}

.work-grid {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 50px;
}

.work-grid .work-item {
  flex-basis: calc(50% - 15px);
  max-width: calc(50% - 15px);
  position: relative;
  margin-bottom: 30px;
  border-radius: 10px;
  border: solid 2px var(--background-transparent);
  overflow: hidden;
  cursor: pointer;

  .image {
    img {
      width: 100%;
      height: auto;
      margin-bottom: -10px;
    }
  }

  .content {
    width: calc(100% - 80px);
    position: absolute;
    background-color: var(--background);
    color: var(--foreground);
    border-radius: 80px;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;

    .title {
      font-size: 18px;
      font-weight: 300;
    }

    .action {
      text-align: right;
      font-size: 14px;

      a {
        display: block;
        font-weight: 200;
        height: 100%;
        position: relative;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: -10px;
          left: -10px;
          right: -10px;
          bottom: -10px;
        }
      }
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 80px;
    transform-origin: center calc(100% - 25px);
    transform: scale(0);
    will-change: border-radius, transform;
    transition: transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 300ms,
      border-radius cubic-bezier(0.25, 0.46, 0.45, 0.94) 300ms;
    background-color: var(--background-transparent-light);
    backdrop-filter: blur(3px);
  }

  &:hover::after {
    transform: scale(1);
    border-radius: 6px;
  }

  &:nth-child(even) {
    margin-left: 15px;
  }

  &:nth-child(odd) {
    margin-right: 15px;
  }
}

@media screen and (max-width: 800px) {
  .work-grid {
    display: block;
  }

  .work-grid .work-item {
    max-width: unset;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
