.article-dialog {
  position: fixed;
  top: 50px;
  height: calc(100% - 100px);
  width: 90%;
  max-width: 900px;
  background-color: var(--foreground);
  border: none;
  padding: 50px 0 0 0;
  border-radius: 10px;
  overflow: hidden;

  nav {
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--foreground);
    border-bottom: solid 1px #dbdbdb;
    display: flex;
    padding: 0 20px;

    .title {
      line-height: 49px;
      font-size: 27px;
      color: var(--text);
      font-weight: 400;
    }

    .close {
      height: 100%;
      width: auto;
      margin-left: auto;
      line-height: 49px;
      padding-right: 26px;
      position: relative;
      font-size: 20px;
      font-weight: 200;
      cursor: pointer;
      color: var(--text);

      span {
        position: absolute;
        width: 20px;
        height: 20px;
        border: solid 1px var(--text);
        border-radius: 50%;
        top: 50%;
        right: 0;
        margin-top: -10px;

        &::after {
          content: '';
          display: block;
          width: 12px;
          height: 1px;
          background-color: var(--text);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  #inner-content {
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
    padding: 20px;

    .title {
      width: 100%;
      height: auto;
      padding-bottom: 45px;
      position: relative;

      h1 {
        font-weight: 300;
        font-size: 50px;
        margin-bottom: 15px;
        color: var(--text);

        @media screen and (max-width: 620px) {
          font-size: 22px;
        }
      }

      .user {
        display: flex;
        align-items: center;
        padding-bottom: 15px;

        .avatar {
          width: 70px;
          height: 70px;

          img {
            width: 100%;
            border-radius: 50%;
          }
        }

        .info {
          flex: 1;
          max-width: calc(100% - 70px);
          padding-left: 10px;

          h3 {
            font-weight: 300;
            color: var(--text);
          }

          a {
            color: var(--text) !important;
          }
        }
      }

      .article-details {
        width: 100%;

        h5 {
          color: var(--text);
          font-weight: 400;
        }

        ul.tags {
          display: flex;
          list-style: none;
          margin: 0;
          padding: 10px 0;

          .tag {
            padding-right: 5px;

            a {
              display: block;
              padding: 5px;
              color: var(--background);
            }
          }

          @media screen and (max-width: 620px) {
            display: none;
          }
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        bottom: 30px;
        background-color: var(--background);
        width: calc(100% - 40px);
        height: 4px;
        border-radius: 5px;
        transform: translateX(-50%);
        opacity: 0.5;
      }
    }

    article.content {
      width: 100%;
      padding: 0 20px 40px 20px;

      & > .highlight {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0;
        width: calc(100% + 80px);
      }

      &:not(.highlight) {
        font-weight: 300;
        color: var(--text);
        line-height: 1.4;
        font-size: 18px;
      }

      p {
        margin-bottom: 20px;
      }

      h3 {
        margin-bottom: 5px;
      }

      h4 {
        font-weight: 500;
        margin-bottom: 3px;
      }

      @media screen and (max-width: 620px) {
        padding: 0;
        & > .highlight {
          width: calc(100% + 40px);
        }
      }
    }

    footer {
      width: 80%;
      max-width: 500px;
      margin: auto;
      padding-top: 25px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--foreground);
      border-top: solid 1px var(--background-transparent);
    }
  }

  &::backdrop {
    background-color: rgba(44, 44, 44, 0.6);
    backdrop-filter: blur(10px);
  }

  &.loading {
    nav,
    #inner-content {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      width: 60px;
      height: 60px;
      border: solid 4px var(--background);
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -30px;
      border-radius: 50%;
      border-bottom-color: var(--foreground);
      border-top-color: var(--foreground);
      animation: LoadSpin infinite linear 1000ms;
    }
  }

  @media screen and (max-width: 620px) {
    height: 100%;
    width: 100%;
    top: 0;
    border-radius: 0;
  }
}
