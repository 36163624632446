header.app-header {
  width: 100%;
  height: 400px;
  background-color: var(--background);
  position: relative;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;

  .inner-content {
    text-align: center;
  }

  h1 {
    font-size: 100px;
    color: var(--foreground);

    @media screen and (max-width: 620px) {
      font-size: 70px;
    }
  }

  h3 {
    font-weight: 400;
    color: var(--foreground);
    opacity: 0.8;
  }

  &::after,
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 100vw 100px 0;
    position: absolute;
    bottom: -100px;
    left: 0;
  }

  &::after {
    border-color: transparent var(--background) transparent transparent;
  }

  &::before {
    border-color: transparent var(--background) transparent transparent;
    filter: blur(5px);
    bottom: -104px;
    opacity: 0.7;

    @media (prefers-color-scheme: light) {
      display: none;
    }
  }
}
